// setting up break points
const BREAKPOINTS = {
  phone: 37.5,
  tablet: 59.375,
  laptop: 81.25,
};

// desktop first approach
export const QUERIES = {
  phones: `(min-width: ${BREAKPOINTS.phone}rem)`,
  tablets: `(min-width: ${BREAKPOINTS.tablet}rem)`,
  laptops: `(min-width: ${BREAKPOINTS.laptop}rem)`,
};
