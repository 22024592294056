import * as React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import Footer from "../components/Footer";
import { QUERIES } from "../constants";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

interface IndexPageProps {
  location: {
    pathname: string;
  };
}

const { useRef, useEffect } = React;

// markup
const IndexPage = ({ location }: IndexPageProps) => {
  // const mainRef = useRef();
  // const mainChildren = gsap.utils.selector(mainRef);

  // const changeBackground = () => {
  //   // sectionBackgrounds
  //   const sections = gsap.utils.toArray(mainChildren("section"));

  //   gsap.registerPlugin(ScrollTrigger);

  //   const bgColors = ["#018555", "#E1572B", "#F4EEE5", "#1D1919"];
  //   // @ts-nocheck
  //   // @ts-ignore
  //   sections.forEach(function (section, index) {
  //     // @ts-ignore
  //     ScrollTrigger.create({
  //       // @ts-nocheck
  //       // @ts-ignore
  //       trigger: section,
  //       scrub: 0.5,
  //       start: "top top",
  //       end: "bottom center",
  //       animation: gsap.to("header", {
  //         // @ts-nocheck
  //         // @ts-ignore
  //         backgroundColor: bgColors[index],
  //         immediateRender: false,
  //       }),
  //       toggleActions: "restart none none reverse",
  //     });
  //   });
  // };

  // useEffect(() => {
  //   changeBackground();
  //   ScrollTrigger.addEventListener("refreshInit", function () {});
  // }, []);

  return (
    <Layout location={location}>
      <Header />
      {/* @ts-ignore */}
      <Home>
        <title>Home</title>
        <Hero>
          <HeroText>
            <Spacer size="90" />
            <h1> Build faster, go further. </h1>
            <Spacer size="30" />
            <Text>
              We are solving customer pain points regarding online products and
              service transaction settlements through our suite of financial
              technology products and acquired entities. Like why do customers
              still have bottlenecks regarding payment settlement in the 21st
              century?
            </Text>
            <Spacer size="60" />
            <HeroButton href="mailto:info@africafintech.co.uk" role="button">
              <span>Get in touch </span>
              <svg
                width="35"
                height="8"
                viewBox="0 0 35 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.1036 4.35355C34.2988 4.15829 34.2988 3.84171 34.1036 3.64645L30.9216 0.464466C30.7263 0.269204 30.4097 0.269204 30.2145 0.464466C30.0192 0.659728 30.0192 0.976311 30.2145 1.17157L33.0429 4L30.2145 6.82843C30.0192 7.02369 30.0192 7.34027 30.2145 7.53553C30.4097 7.7308 30.7263 7.7308 30.9216 7.53553L34.1036 4.35355ZM0.25 4.5H33.75V3.5H0.25V4.5Z"
                  fill="#080A0A"
                />
              </svg>
            </HeroButton>
          </HeroText>
          <HeroImage>
            <StaticImage
              src="../images/hero-bg.jpg"
              alt=" A young black man gazing"
              loading="eager"
            />
          </HeroImage>
        </Hero>
        <Second>
          <H2>What we’re building</H2>
          <Spacer size="30" />
          <Text>
            At Africa Fintech Limited, we are a holding company soon releasing a
            suite of innovative financial technology products cutting across the
            financial ecosystem across Nigeria and the United Kingdom.
          </Text>
        </Second>
        <Third id="ourMission">
          <HeadingWrapper>
            <H3>
              <span> Our Mission </span>
              <span> Our Mission </span>
              <span> Our Mission </span>
              <span> Our Mission </span>
            </H3>
          </HeadingWrapper>
          <TextWrapper>
            <Text>
              Our mission is simple, to make payment settlements and financial
              transactions more accessible and faster for a billion people in
              the next 10 years. Yes, we are very ambitious with our targets. We
              empathetically want to solve the financial technology
              inaccessibility for the incoming internet users on the Africa
              continent and globally.{" "}
            </Text>
            <Spacer size="10" />
            <Text>
              We believe you learn how to address Africa’s financial services
              bottlenecks by focusing on leveraging optimal product and service
              user experiences at an institutional level while ensuring that a
              strategic design is phased in for scale across all our suite of
              financial service software.
            </Text>
          </TextWrapper>
        </Third>
        <Fourth>
          <div>
            <H2>Can’t find what you’re looking for?</H2>
            <Spacer size="30" />
            <Text>We’ll help you find the right answer.</Text>
            <Spacer size="50" />
            <ButtonLink
              href="mailto:info@africafintech.co.uk"
              bg="var(--green)"
              color="var(--white)"
            >
              <span>Get in touch</span>
              <svg
                width="35"
                height="8"
                viewBox="0 0 35 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.1036 4.35355C34.2988 4.15829 34.2988 3.84171 34.1036 3.64645L30.9216 0.464466C30.7263 0.269204 30.4097 0.269204 30.2145 0.464466C30.0192 0.659728 30.0192 0.976311 30.2145 1.17157L33.0429 4L30.2145 6.82843C30.0192 7.02369 30.0192 7.34027 30.2145 7.53553C30.4097 7.7308 30.7263 7.7308 30.9216 7.53553L34.1036 4.35355ZM0.25 4.5H33.75V3.5H0.25V4.5Z"
                  fill="#080A0A"
                />
              </svg>
            </ButtonLink>
          </div>
        </Fourth>
      </Home>
      <Footer />
    </Layout>
  );
};

const Home = styled.main`
  // margin-top: -100px;
`;

const Hero = styled.section`
  background-color: var(--green);
  color: var(--white);
  display: flex;
  min-height: 650px;
  align-items: flex-end;
  @media ${QUERIES.tablets} {
    align-items: initial;
  }
`;

const HeroText = styled.div`
  padding-inline: 25px;

  h1 {
    // font-size: calc(75 / 16 * 1rem);
    font-size: clamp(4rem, 9vw + 1rem, 6.75rem);
    font-weight: var(--weight-medium);
    line-height: 1;
  }

  @media ${QUERIES.tablets} {
    flex-basis: 60%;
    padding-inline: 40px;
    padding-block-start: 90px;
    padding-block-end: 100px;

    > * {
      max-width: 40ch;
    }

    h1 {
      overflow-wrap: break-word;
      hyphens: auto;
    }
  }

  @media ${QUERIES.laptops} {
    flex-basis: 50%;
  }
`;
const Text = styled.p`
  font-size: calc(24 / 16 * 1rem);
  // hyphens: auto;
  // word-wrap: wrap;
`;

const ButtonLink = styled.a<{ bg?: string; color?: string }>`
  cursor: pointer;
  padding-block: 12px;
  font: inherit;
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bg || "var(--white)"};
  color: ${(props) => props.color || "var(--black-100)"};
  font-weight: var(--weight-medium);
  font-size: calc(24 / 16 * 1rem);
  width: 100%;

  path {
    fill: ${(props) => props.color};
  }
`;

const HeroButton = styled(ButtonLink)`
  width: calc(100% + 50px);
  margin-left: -25px;

  @media ${QUERIES.tablets} {
    margin: 0;
    width: 100%;
  }
`;

const HeroImage = styled.div`
  display: none;

  @media ${QUERIES.tablets} {
    display: flex;
    flex-basis: 40%;
  }

  @media ${QUERIES.laptops} {
    flex-basis: 50%;
  }
`;

const H2 = styled.h2`
  font-size: calc(51 / 16 * 1rem);
  font-weight: var(--weight-medium);
  overflow-wrap: break-word;
  hyphens: auto;

  @media ${QUERIES.tablets} {
    font-size: calc(65 / 16 * 1rem);
    max-width: 40ch;
  }
`;

const Second = styled.section`
  background-color: var(--orange);
  padding-block: 100px 150px;
  padding-inline: 25px;
  color: var(--white);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='1228' height='349' viewBox='0 0 1228 349' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M269.312 2L1332 248.13L1332 348L2 348L269.312 2Z' stroke='white'/%3E%3Cpath d='M166.542 348L368.803 348L269.043 2.64423L166.542 348Z' stroke='white'/%3E%3C/svg%3E%0A");
  background-position-y: 130%;
  background-size: auto 40%;
  background-position-x: left;

  @media ${QUERIES.tablets} {
    background-position-x: right;
    background-position-y: 110%;
    padding-block: 70px;
    padding-inline: 40px;
    background-size: auto 60%;

    p {
      max-width: 40ch;
    }
  }
`;

const Third = styled.section`
  background-color: var(--grey);
  padding-inline-end: 25px;
  padding-block: 100px 70px;
  display: flex;

  @media ${QUERIES.tablets} {
    padding-inline: 40px;
  }
`;

const HeadingWrapper = styled.div`
  flex-basis: 30%;

  @media ${QUERIES.tablets} {
    flex-basis: 65%;
  }
`;

const H3 = styled.h3`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 1.5rem;
  span {
    writing-mode: vertical-lr;
    font-size: min(9vw, 60px);
    font-weight: 800;
    line-height: 1;
    transform: rotate(-180deg);

    &:last-child {
      display: none;
      opacity: 30%;
      font-weight: 500;
    }

    &:nth-child(3) {
      opacity: 50%;
    }

    &:nth-child(2) {
      opacity: 75%;
    }

    @media ${QUERIES.tablets} {
      writing-mode: initial;
      transform: rotate(0);
      line-height: 1.25;

      :last-child {
        display: block;
        font-size: clamp(3.5rem, 7vw + 1rem, 6.75rem);
      }

      :first-child {
        font-weight: 800;
        font-size: clamp(6.25rem, 12vw - 1.25rem, 11.25rem);
      }

      :nth-child(2) {
        font-weight: 700;
        font-size: clamp(5.25rem, 10vw, 10.25rem);
      }

      :nth-child(3) {
        font-weight: 700;
        font-size: clamp(4.25rem, 9vw, 9.25rem);
      }
    }
  }

  @media ${QUERIES.tablets} {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 65%;
  p {
    line-height: 1.7;
    font-size: 1.25rem;
  }
  @media ${QUERIES.tablets} {
    flex-basis: 35%;
  }
`;

const Fourth = styled.section`
  background-color: var(--black-200);
  padding-block: 150px;
  padding-inline: 25px;
  text-align: center;
  color: var(--white);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${QUERIES.tablets} {
    padding-inline: 40px;
    padding-block: 100px;

    > div {
      max-width: 70%;
      margin: 0 auto;
    }
  }
`;

export default IndexPage;
